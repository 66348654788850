html, body, #root {
  height: 100%;
  width: 100%;
}

.spinner {
  border: 4px solid #f3f3f3; /* 边框颜色 */
  border-top: 4px solid #3498db; /* 顶部边框颜色 */
  border-radius: 50%; /* 边框圆角 */
  width: 30px; /* 宽度 */
  height: 30px; /* 高度 */
  animation: spin 2s linear infinite; /* 动画属性 */
  margin: 0 auto; /* 居中 */
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* 旋转角度 */
  }
  100% {
    transform: rotate(360deg); /* 旋转角度 */
  }
}

.contain {
  width: 100%;
  height: 100%;
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #EDF4FE;
  position: relative;
  box-sizing: border-box;
}

.bbg-header {
  position: absolute;
  top: 36px;
  left: 36px;
  display: flex;
  align-items: center;
}

.bbg-icon {
  background: url(./images/Icon-app.png) no-repeat center / contain;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.content-view {
  padding: 48px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
}

.tips {
  font-size: 12px;
  color: #666;
  margin-top: 48px;
}

.join-button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #2893F7;
  color: #fff;
}

.content-view-pc {
  display: flex;
  padding: 48px;
  margin: 0 100px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: center;
}

.download-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 80px;
}

.download-ewm {
  width: 400px;
  height: 400px;
}

.wx-tips {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: end;
  padding: 8px 4px;
}